<template>
  <div>
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="breadcrumb">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/menuManage' }">菜单管理</el-breadcrumb-item>
          <el-breadcrumb-item>编辑菜单</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="form-box">
        <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px">
          <el-form-item label="菜单图标" prop="icon">
            <el-input v-model="form.icon" placeholder="请输入菜单图标"></el-input>
          </el-form-item>
          <el-form-item label="菜单名称" prop="title">
            <el-input v-model="form.title" placeholder="请输入菜单名称"></el-input>
          </el-form-item>
          <el-form-item label="菜单地址" prop="url">
            <el-input v-model="form.url" placeholder="请输入菜单地址"></el-input>
          </el-form-item>
          <el-form-item label="菜单配置">
            <el-collapse v-model="activeNames" @change="handleChange">
              <el-collapse-item title="新增菜单配置" name="1">
                <el-tree
                  :data="dataTree"
                  node-key="id"
                  default-expand-all
                  :expand-on-click-node="false"
                  :default-checked-keys="[2]"
                  @node-click="handleNodeClick"
                  :props="defaultProps">
                </el-tree>
              </el-collapse-item>
            </el-collapse>
          </el-form-item>
          <el-form-item label="">
            <el-button type="primary" @click="onSubmit" size="small">提交</el-button>
            <el-button size="small" @click="$router.push('/menuManage')">返回</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {},
      rules: {
        icon:[{ required: true, message: '必填项', trigger: 'blur' }],
        title:[{ required: true, message: '必填项', trigger: 'blur' }],
        url:[{ required: true, message: '必填项', trigger: 'blur' }]
      },
      loading: false,
      activeNames: ['1'],
      dataTree: [],
      defaultProps: {
        children: 'childTree',
        label: 'title'
      },
      parentId: 0,
      type: 0
    }
  },
  methods: {
    handleChange(val) {},
    handleNodeClick(e) {
      this.parentId = e.id
      this.type = e.type
    },
    loadTree() {
      this.$ajax.get('menuStructure').then(res=> {
        this.dataTree = res.data
      })
    },
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if(this.parentId == 0 && this.form.parentId != 0) {
            this.$message.error('请选择菜单配置')
            return
          }
          this.loading = true
          let form = {
            id: this.form.id,
            parentId: this.parentId,
            icon: this.form.icon,
            title: this.form.title,
            type: this.type,
            url: this.form.url
          }
          this.$ajax.post("menuManageUpdate", form).then((res) => {
            this.loading = false;
            this.$message.success('成功')
            this.$router.push('/menuManage')
          }).catch(err=>{
            this.loading = false
          })
        }
      })
    }
  },
  mounted() {
    if (this.$route.params == null) {
			this.$router.push('/menuManage')
		} else {
      this.form = this.$route.params
      console.log(this.form)
    }
    this.loadTree()
  }

}
</script>
<style lang="less" scoped>
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.breadcrumb {
  padding: 15px 10px;
  .el-breadcrumb {
    font-size: 18px;
  }
}
.el-input { width:50%; }
.el-collapse {
  border: 1px solid #DCDFE6;
  border-radius: 4px;
  overflow: hidden;
  width: 50%;
}
.el-collapse-item {
  box-shadow: 0px 0px 0px rgba(211, 211, 211, 0.5);
  border: 0px solid #eee;
  border-radius: 0px;
  margin: 0px;
  padding: 0px;
}
/deep/.el-collapse-item__header {
  height: 42px;
  padding: 0 10px;
  border-bottom: 0;
}
/deep/.el-tree-node__label {
  font-size: 15px;
  line-height: 10px;
  letter-spacing: 2px;
}
/deep/.el-tree-node__content {
  height: 35px;
}
/deep/.el-tree-node:focus>.el-tree-node__content {
  background-color: #409eff;
  color: white;
}
</style>
<style>

</style>